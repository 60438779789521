import React, { Component } from 'react';
import Layout from './Layout';

class App extends Component {
  render() {
    return (
      <div>
       <Layout/>
      </div>
    );
  }
}

export default App;
