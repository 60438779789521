import React, { Component } from 'react';
import Title from './styles/Title';
import Text from './styles/Text';

import RedChair from './images/red-chair.jpg';
import KueLogo from './images/kue-logo.png';

class StayTuned extends Component {
  render() {
    return (
      <div style={{position:'absolute', top:0, bottom:0, left:0, right:0, margin:0, overflow:'hidden'}}>
        <img src={RedChair} alt='' style={{position:'absolute', objectFit:'cover', height:'100%', minWidth:'100%', marginLeft: window.innerWidth < 500 ? -30:0, zIndex:-1}}/>
        <div style={{marginLeft:'5%', marginTop:'5%',}}>
          <img src={KueLogo} alt='' style={{position:'relative', objectFit:'contain', height:175, width:175, marginBottom:20}}/>
          <Title style={{fontFamily:'system-ui', color:'white'}}>
            Stay Tuned
          </Title>
          <a href='https://provider.kue.tv' style={{ textDecoration: 'none' }}>
            <Text>
              Content Provider Site
            </Text>
          </a>
          <a href='mailto:info@kue.tv' style={{ textDecoration: 'none' }}>
            <Text>
              Contact
            </Text>
          </a>
        </div>
      </div>
    );
  }
}

export default StayTuned;
