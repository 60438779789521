import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import StayTuned from './StayTuned';

class Layout extends Component {
  render() {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <div>
          <Route path='/' exact component={StayTuned}/>
        </div>
      </Router>
    );
  }
}

export default Layout;
